<template>
  <v-list-group
    :color="gradient !== 1 ? 'white' : undefined"
    :prepend-icon="item.icon ? item.icon : 'mdi-account'"
    eager
    v-bind="$attrs"
  >
    <template v-slot:activator>
      <v-list-item-avatar v-if="item.avatar">
        <v-img :src="item.avatar" />
      </v-list-item-avatar>

      <v-list-item-content v-if="item.name">
        <v-list-item-title v-text="item.name" />
      </v-list-item-content>
    </template>

    <template v-for="(child, i) in item.modules">
      <default-list-group
        v-if="child.items"
        :key="`sub-group-${i}`"
        :item="child"
      />

      <default-list-item
        v-if="!child.items"
        :key="`child-${i}`"
        :item="child"
      />
    </template>
  </v-list-group>
</template>

<script>
  // Utilities
  import { get, sync } from 'vuex-pathify'

  export default {
    name: 'DefaultListGroup',

    components: {
      DefaultListItem: () => import('./ListItem'),
    },

    data() {
      return {
        show: false
      }
    },

    props: {
      item: {
        type: Object,
        default: () => ({}),
      },
    },

    computed: {
      ...sync('app', [
        'mini',
      ]),
      gradient: get('user/drawer@gradient'),
    },
  }
</script>
